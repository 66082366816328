@import url('https://p.typekit.net/p.css?s=1&k=acm6eje&ht=tk&f=10294.10300.10302.14032.14033.14038.14039&a=53911303&app=typekit&e=css');

@font-face {
  font-family: 'brandon-grotesque';
  src: url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/951aca/000000000000000077359d51/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/951aca/000000000000000077359d51/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/951aca/000000000000000077359d51/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/939926/000000000000000077359d5f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/939926/000000000000000077359d5f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/939926/000000000000000077359d5f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Barlow;
  src: url('./assets/Fonts/Barlow-Regular.ttf');
}

@font-face {
  font-family: Oswald;
  src: url('./assets/Fonts/Oswald-VariableFont_wght.ttf');
}

:root {
  /* ----- Colors */
  --primary-color: #424242;
  --secondary-color: #FFFFFF;
  --orange-theme: #EF6F6C;
  --orange-theme-aux: #f4918f;
  --green-theme: #394E3F;
  --green-theme-aux: #D7D8B8;
}

body {
  font-family: Barlow;
  overflow-y: scroll !important;
}

/* ----- Links */
a { text-decoration: none; color: inherit; cursor: pointer; font-family: Barlow; }

button {
  background: inherit;
  outline: none;
  border: none;
  cursor: pointer;
}

.d-flex { display: flex; }
.flex-wrap { flex-wrap: wrap; }
.d-col { display: flex; flex-direction: column; }
.d-row { display: flex; flex-direction: row; }
.f-grow { flex-grow: 1; }
.d-grid { display: grid; width: 100%; }
.grid-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }

/* ----- Width */
.w-100 { width: 100%; }

/* ----- Height */
.h-100 { height: 100%; }

.position-relative { position: relative; }
.position-absolute { position: absolute; }
.position-sticky { position: sticky; }

/* ----- Border-Radius */
.border-round-sm { border-radius: .25rem; }
.border-round-md { border-radius: .5rem; }
.border-round-lg { border-radius: 1.5rem; }
.border-round-full { border-radius: 100%; }

/* ---- Justify */
.justify-center { justify-content: center; }
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.justify-between { justify-content: space-between; }

/* ---- Align */
.align-center { align-items: center; }
.align-start { align-items: flex-start; }
.align-end { align-items: flex-end; }

.font-upper { text-transform: uppercase; }
.font-center { text-align: center; }
.font-left { text-align: left; }
.font-right { text-align: right; }
.font-bold { font-weight: bold; }

.font-light { color: var(--secondary-color); }
.font-dark { color: var(--primary-color); }
.font-green { color: var(--green-theme); }

.font-oswald {
  font-family: Oswald, serif;
}
.font-barlow {
  font-family: Barlow, serif;
}

.font-w-lg { font-weight: 800 !important; }
.font-capitalize { text-transform: capitalize; }
.font-italic { font-style: italic; }
.font-underline {
  text-decoration: underline;
  text-decoration-color: var(--green-theme);
}

.font-x-large {
  font-size: 62px;
  font-weight: 300;
  line-height: 39.6px;
  letter-spacing: -0.01em;
}

.font-large {
  font-size: 36px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: 1px;
}

.font-body {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.font-body-sm {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0.02em;
}

.font-body-xs {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.02em;
}

.btn-primary {
  background: var(--green-theme-aux);
  color: var(--green-theme);
  transition: .25s ease;
  padding: 10px 40px;
  font-weight: bold;
}
.btn-primary:hover {
  color: var(--green-theme-aux);
  background: var(--green-theme);
}

.btn-secondary {
  text-decoration: underline;
  color: var(--green-theme);
  text-decoration-color: var(--secondary-color);
}
.btn-secondary:hover {
  text-decoration-color: var(--green-theme);
  font-weight: 700;
}

.btn-outline {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--green-theme);
  line-height: 22.96px;
  padding: 10px 40px;
}
.btn-outline:hover {
  filter: brightness(80%);
}

.btn-link {
  font-family: Barlow, serif;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: var(--green-theme);
  line-height: 22.96px;
  padding: 10px 40px;
}

.btn-link:hover,
.nav-link:hover {
  text-decoration: underline;
  text-decoration-color: var(--green-theme);
  -webkit-text-stroke: .5px var(--green-theme);
}

@media screen and (max-width: 768px) {
  .font-body-sm { font-size: 14px; }

  .font-large { font-size: 24px; }

  .font-x-large { font-size: 46px ;}
}

@media screen and (max-width: 300px) {
  .font-x-large { font-size: 32px ;}
}